import Vue from 'vue'
import AMap from 'vue-amap';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import '@/style/common.css' // global css
// import Cookies from 'js-cookie'

// vant 组件引入
import Vant from 'vant';
import 'vant/lib/index.css';
import VueCanvasPoster from 'vue-canvas-poster';

Vue.use(Vant);
Vue.use(AMap);
Vue.config.productionTip = false
Vue.use(VueCanvasPoster)
Vue.prototype.$host = '/baidu'
    // 初始化vue-amap
AMap.initAMapApiLoader({
    // 高德key
    key: '8859b8e7a76bd03f160f3a208cfe9b1e',
    // 插件集合 （插件按需引入）
    plugin: ['AMap.Geolocation']
});
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')


// 微信分享公用方法, 所有的需要分享的页面都会调用
async function _weiXin(url, wxData) {
    var shareData = Object.assign({
        title: "吉利华阳",
        desc: "吉利华阳",
        imgUrl: "https://jlhyapi.dexian.ren//upload/images/2021/11/c29730eb-14e.png",
    }, wxData);

    // 从后台获取微信签名
    var wxDataRsps = await axios.get("http://jlhyapi.dexian.ren/api/weixinjssdk/index", {
        params: {
            url: url
        }
    });

    // 判断是否成功
    if (wxDataRsps) {
        wx.config({ // 设置微信参数
            debug: false,
            appId: wxDataRsps.AppId, // 必填，公众号的唯一标识
            timestamp: wxDataRsps.Timestamp, // 必填，生成签名的时间戳
            nonceStr: wxDataRsps.NonceStr, // 必填，生成签名的随机串
            signature: wxDataRsps.Signature, // 必填，签名
            jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
        });
        wx.ready(function() {
            wx.onMenuShareAppMessage({
                title: shareData.title, // 分享标题
                desc: shareData.desc, // 分享描述
                link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareData.imgUrl, // 分享图标
                success: function() {
                    // 用户确认分享后执行的回调函数
                    console.log("分享成功");
                },
                cancel: function() {
                    // 用户取消分享后执行的回调函数
                    console.log("取消分享");
                }
            });
            wx.onMenuShareTimeline({
                title: wxData.title, // 分享标题
                desc: wxData.desc, // 分享描述
                link: wxData.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: wxData.imgUrl, // 分享图标
                success: function() {
                    // 用户确认分享后执行的回调函数
                },
                cancel: function() {
                    // 用户取消分享后执行的回调函数
                }
            });
            wx.error(function(res) {
                console.log("失败");
                // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            });
        });
    }
}

Vue.prototype.$WexinShare = _weiXin;