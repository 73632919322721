import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

import huayang from './modules/huayang'

/* Layout */
import Layout from '@/layout'

const router = new Router({
    routes: [{
            path: '/',
            component: Layout,
            children: [{
                path: '',
                name: 'home',
                meta: {
                    title: "首页"
                },
                component: () =>
                    import ('@/views/huayang/home.vue')
            }, ]
        },
        huayang
    ]
});

// 动画效果
router.beforeEach((to, from, next) => {
    console.log(to.path)
    console.log(from.path)

    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (to.path == from.path || ((to.path == '/' || to.path == '/serve' || to.path == '/center') && (from.path == '/' || from.path == '/serve' || from.path == '/login' || from.path == '/village'))) {
        store.commit('UPDATE_DIRECTION', "none")
    } else if (to.path.indexOf(from.path) != -1 || (from.name && to.name.indexOf(from.name) != -1)) {
        store.commit('UPDATE_DIRECTION', "forward")
    } else {
        store.commit('UPDATE_DIRECTION', "reverse")
    }
    next();
})

export default router